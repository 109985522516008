import React from 'react';

export default function PrivacyPolicy({ data }) {

  return (
    <>
      <h1>PrivacyPolicy</h1>
    </>
  );
}

